@import "../theme/colors.scss";

.header {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 0px;
    padding-bottom: 0px;
    font-size: 18px;
    height: auto;
    min-height: 64px;
    line-height: 64px;
    position: relative;
    border-bottom: solid 1px $color-divider-dark;
}

.headerContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.avatarContainer {
    margin-left: 16px;
}

.menuButton {
    z-index: 601;
    margin-right: 16px;
}

.avatar {
    cursor: pointer;
}

.opcoesItem {
    margin-bottom: 4px;
}

.optionDivider {
    margin-top: 8px;
    margin-bottom: 8px;
}

.menuItemContainer {
    cursor: pointer;
    padding: 4px;
    border-radius: 4px;

    &:hover {
        background-color: rgb(252, 250, 250);
    }
}

.contentOnRight {
    display: flex;
    align-items: center;

    @media (max-width: 575px) {
        width: 100%;
        flex-wrap: wrap;
    }
}

.inputSearchCompany {
    min-width: 330px;

    @media (max-width: 575px) {
        min-width: 100%;
    }
}
