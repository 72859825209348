$color-primary: #f27612;
$color-primary-dark: #b94700;
$color-primary-light: #ffa748;
$color-text-on-primary: #000000;

$color-accent: #1565c0;
$color-accent-dark: #003c8f;
$color-accent-light: #5e92f3;
$color-text-on-accent: #ffffff;

$hover-color: rgb(240,240,240);
$background-color: #ffffff;
$background-color-dark: rgb(250, 250, 250);

$color-text-primary-dark: rgba(0, 0, 0, 0.87);
$color-text-secondary-dark: rgba(0, 0, 0, 0.54);
$color-text-disabled-dark: rgba(0, 0, 0, 0.38);
$color-text-hint-dark: rgba(0, 0, 0, 0.38);
$color-divider-dark: rgba(0, 0, 0, 0.12);

$color-text-primary-light: rgba(255, 255, 255, 1);
$color-text-secondary-light: rgba(255, 255, 255, 0.7);
$color-text-disabled-light: rgba(255, 255, 255, 0.5);
$color-text-hint-light: rgba(255, 255, 255, 0.5);
$color-divider-light: rgba(255, 255, 255, 0.24);

$color-error: #f5222d;
